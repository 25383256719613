import React from 'react';

function SurveySteps(props) {
    const { step, total } = props;

    return (
        <div className="survey-app-steps">
            {`${step + 1}/${total} `}
        </div>
    );
}

export default SurveySteps;