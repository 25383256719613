import React, { useState, useEffect } from 'react';
import Modal from 'react-awesome-modal';
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import api from './utils/instances';

import SurveySteps from './Survey/SurveySteps';
import SurveyQuestion from './Survey/SurveyQuestion';
import SurveyGreetings from './Survey/SurveyGreetings';
import logoWhite from './logo-blanco.svg';
import logoAzul from './logo-azul.svg';
import './styles/App.scss';

function App() {
  const [idle, setIdle] = useState(true);
  const [init, setInit] = useState(false);
  const [modalVisible, setModalVisible] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [step, setStep] = useState(0);
  const [surveys, setSurveys] = useState([]);
  const [survey, setSurvey] = useState({});
  const [answers, setAnswers] = useState([]);
  const [isGreeting, setIsGreeting] = useState(false);

  // Survey starts
  const initSurvey = () => {
    setIdle(false);
    setInit(true);
  }

  // Survey ends
  const resetSurvey = () => {
    setStep(0);
    setIdle(true);
    setInit(false);
    setAnswers([]);
  }

  // Fetch surveys from API
  const fetchSurveys = async () => {
    const response = await api.get('surveys');
    const surveys = await response.data;

    setSurveys(surveys);
  }

  // Survey usage configuration
  const selectSurvey = (surveySelect) => {
    setSurvey(surveySelect);
    setQuestions(surveySelect.questions);
    setModalVisible(false);
  }

  // Callback when user select an answer
  const selectAnswer = (answer) => {
    const ans = {
      survey_id: survey.id,
      question_id: questions[step].id,
      answer
    }

    const _answers = [ ...answers ];
    _answers.push(ans);
    
    setAnswers(_answers);     
  }

  const submitAnswers = async ($answers) => {
    let respTimeout;
    const response = await api.post('answers', $answers);

    let status = await response.data;
    
    if(status.success) {
      console.log('Success!');
      setIsGreeting(true);
      clearTimeout(respTimeout);
      respTimeout = setTimeout(() => {
        resetSurvey();
        setIsGreeting(false);
      }, 5000);
    }
  }

  useEffect(() => {
    if(init) {
      // If not the last question
      if( step + 1  !== questions.length ) {
        setStep(step + 1);
      }
      else {
        // Is the last question
        submitAnswers( answers );
      }
    } 
  }, [answers]);

  // Lifecycle hook
  useEffect(() => {
    fetchSurveys();

    // Track user actions to reset or not
    let timeout_reboot;
    function reboot() {
      clearTimeout(timeout_reboot);
      timeout_reboot = setTimeout(() => {
        resetSurvey();
      }, 30000); 
    }

    // Subscribe events to document
    document.addEventListener('click', reboot);
    document.addEventListener('mousemove', reboot);
    document.addEventListener('keyup', reboot);
    document.addEventListener('keydown', reboot);

    // Unsubscribe events if component unmount
    return () => {
      document.removeEventListener('click', reboot);
      document.removeEventListener('mousemove', reboot);
      document.removeEventListener('keyup', reboot);
      document.removeEventListener('keydown', reboot);
    }
    
  }, []);
  
  return (
    <div className={ `survey-app ${ init && 'is-init'} ${ isGreeting && 'is-greeting' }` }>
      <div className={`survey-app__init  ${ init && 'on-top' }`}>
        <img
          src={ !init ? logoWhite : logoAzul } 
          alt="Logo"
          className={`survey-app-logo ${ idle && 'pulse' }`} />
        <button
          className={ `button ${ init && 'is-hidden' }` }
          onClick={ () => initSurvey() }>
            Iniciar
        </button>
      </div>

      {
        !isGreeting &&
        <TransitionGroup className="survey-app-question">
          {
            questions.length > 0 && 
            <CSSTransition
              key={ questions[step].id }
              timeout={ 1200 }
              classNames="fade">
              <SurveyQuestion question={ questions[step] } onAnswerSelect={ selectAnswer } />
            </CSSTransition>
          }
        </TransitionGroup>
      }

      <SurveySteps step={step} total={ questions.length } />

      <SurveyGreetings />

      <Modal visible={modalVisible && surveys.length} width="600" effect="fadeInUp">
          <div className="survey-app-selector">
              <h1>Configurar encuesta</h1>
              {
                surveys.length &&
                surveys.map( survey => {
                  return (
                    <button onClick={ () => selectSurvey(survey) } key={ survey.id }>
                      { survey.title }
                    </button>
                  );
                })
              }
          </div>
      </Modal>
    </div>
  );
}

export default App;
