const levels = [
    {
        icon: require('./pesimo.svg'),
        text: 'Pesimo'
    },
    {
        icon: require('./malo.svg'),
        text: 'Malo'
    },
    {
        icon: require('./bueno.svg'),
        text: 'Bueno'
    },
    {
        icon: require('./excelente.svg'),
        text: 'Excelente'
    }
];

export default levels;