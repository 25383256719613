import React from 'react';
import satisfactionLevels from './SatisfactionLevels/satisfactionLevels';

function SurveyQuestion(props) {
    const { question, onAnswerSelect } = props;

    const renderQuestion = () => {
        switch(question.question_type) {
            case 'yesno':
                // Si, No
                return (
                    <div className="yesno answer with-icons">
                        <div className="yesno-option icon" onClick={ () => onAnswerSelect(false) }>
                            <img src={ require('./icons/no.svg') } alt="No"/>
                            <span>No</span>
                        </div>
                        <div className="yesno-option icon" onClick={ () => onAnswerSelect(true) }>
                            <img src={ require('./icons/si.svg') } alt="Si"/>
                            <span>Si</span>
                        </div>
                    </div>
                );
            case 'satisfaction':
                // Nivel de Satisfaccion (Caritas)
                function renderLevels() {
                    const options = [];
                    for (let i = 1; i <= 4; i++) {
                        let level = satisfactionLevels[i-1];
                        let option = (
                            <div className="satisfaction-option icon" onClick={ () => onAnswerSelect(i) } key={ i - 1 }>
                                <img src={level.icon} alt=""/>
                                <span>{ level.text }</span>
                            </div>
                        );
                        options.push( option );
                    }
                    return options;
                }

                return (
                    <div className="satisfaction answer with-icons">
                        { renderLevels() }
                    </div>
                )
            case 'text':
                // Campo de texto
                let textValue = '';
                function submitTextAnswer() {
                    onAnswerSelect(textValue);
                }

                function handleTextChange(e) {
                    textValue = e.target.value;
                }

                return (
                    <div className="input-text">
                        <textarea className="input-text-field" onChange={ handleTextChange } />
                        <button className="form-button blue" onClick={ () => submitTextAnswer() }>
                            Continuar
                        </button>
                    </div>
                );
            default: 
                break;
        }
    }

    return (
        <div className="question">
            <h2>{ question.title }</h2>

            <div className="survey-app-answer">
                { renderQuestion() }
            </div>
        </div>
    );
}

export default SurveyQuestion;