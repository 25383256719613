import React from 'react';

function SurveyGreetings({ visible }) {
    return (
        <div className={ `survey-app-greetings ${ visible && 'is-shown' }` } >
            <div className="greetings">
                <h1>¡Gracias!</h1>
                <h2>Tu opinión nos ayuda a mejorar</h2>
            </div>
        </div>
    );
}

export default SurveyGreetings;